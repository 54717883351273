.search {
  &-dropdown-component {
    .search {
      &-dropdown-btn {
        line-height: 1.2;

        &:focus {
          box-shadow: none;
        }
      }

      &-dropdown-content {
        margin-top: -5px;
        margin-left: 2px;
      }

      &-dropdown-header {
        font-size: 12px;

        .add-sbo-btn {
          i {
            font-size: 20px;
            left: -14px;
            top: 6px;
          }
        }
      }

      &-dropdown {
        width: 330px;
        height: 50vh;
        // TODO: Make a utility class when we implement the Design System
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.45);
        border-radius: 0;

        .search {
          &-icon {
            top: 26px;
            left: 32px;
          }

          &-icon-clear {
            top: 26px;
            right: 33px;
            font-size: 18px;
            cursor: pointer;
          }

          &-input {
            width: 100%;
            padding: 0.5rem 2.25rem;
          }

          &-results-wrapper {
            overflow-y: scroll;

            .list-group-item {
              cursor: pointer;

              &:first-child {
                border-top: none;
              }

              &:hover {
                background: var(--color-neutral-50);
              }

              &.selected {
                background: var(--color-primary-50);
              }
            }
          }
        }
      }
    }
  }
}
