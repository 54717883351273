.react-select-container {
  &__control {
    cursor: pointer;
    color: var(--body-text-color);
    background-color: var(--white);
    border-color: $lightGray;
    border-radius: 0;
    min-height: auto;

    &--is-focused {
      color: var(--body-text-color);
      background-color: var(--white);
      border-color: $lightGray;
      outline: 0;
      box-shadow: none;
      border-radius: 0;
    }

    &--is-disabled {
      background-color: $grayWhite;
    }
  }

  &__single-value {
    color: $darkGray;

    &--is-disabled {
      color: $gray;
    }
  }

  &__menu {
    margin-top: 0;
    border-radius: 0;
  }

  &__indicator-separator {
    display: none;
  }

  &__dropdown-indicator {
    padding: 0.6rem;
    color: var(--color-neutral-600);

    svg {
      height: 15px;
    }
  }

  &__value-container {
    padding-top: 0;
    padding-bottom: 0;
  }

  &__option {
    cursor: pointer;

    &:hover,
    &--is-focused {
      background: var(--color-neutral-100);
    }

    &--is-selected {
      background: var(--color-primary-50);
      color: var(--body-text-color);

      &:hover {
        background: var(--color-primary-50);
      }
    }
  }

  &__placeholder {
    color: var(--color-neutral-400);
  }
}
