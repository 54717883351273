// -- Asure Variables /
// -------------------/
$primaryColor:                            #068FBE;
$secondaryColor:                          #8dc63f;
$grayBlack:                               #212121;
$darkGray:                                #4e4e4f;
$gray:                                    #909090;
$lightGray:                               #d4d4d4;
$grayWhite:                               #f0f1f2;
$dangerColor:                             #db0020;
$successColor:                            #21b500;
$warningColor:                            #ff9800;
$infoColor:                               #00bcd4;

$borderRadius:                            2px;
$borderColor:                             $lightGray;
$themeColors: (
  neutral: #808590, // This generates grays that we should probably use instead of those defined above
  primary: $primaryColor,
  accent: $secondaryColor,
  danger: $dangerColor,
  success: $successColor,
  warning: $warningColor,
  info: $infoColor
);

// -- Bootstrap Variables /
// -----------------------/
$theme-colors: (
  'primary': $primaryColor,
  'secondary': $secondaryColor,
  'gray': $darkGray,
  'white': #fff,
  'danger': $dangerColor,
  'success': $successColor,
  'warning': $warningColor,
  'info': $infoColor
);

$primary:                                 var(--color-primary-600);
$secondary:                               var(--color-accent-500);
$border:                                  var(--color-gray-600);
$body-color:                              $darkGray;
$border-color:                            $borderColor;

// Typography
$font-family-base:                        'Roboto', sans-serif;
$font-size-base:                          14px;
$headings-margin-bottom:                  1.5em;
$headings-font-weight:                    300;
$font-weight-light:                       300;
$font-weight-normal:                      400;
$font-weight-bold:                        500;
$text-muted:                              $gray;

//Forms
$input-border-radius:                     0;
$input-disabled-bg:                       $grayWhite;
$input-focus-border-color:                #006c93; //var(--color-primary-700)
$input-focus-width:                       1px;
$input-focus-box-shadow:                  0 0 1px 1px rgba(#006c93, 0.25);
$form-feedback-invalid-color:             $dangerColor;
$input-padding-x:                         0.75rem;

//0 0 0 $input-btn-focus-width $input-btn-focus-color

// TODO: What should the default button sizes be?
$input-btn-padding-y:                     0.5rem;
$input-btn-padding-x:                     1.25rem;

// Buttons
$btn-border-radius:                       $borderRadius;
$btn-border-radius-lg:                    $borderRadius;
$btn-border-radius-sm:                    $borderRadius;

// Alerts
// TODO - Update these values to use the Design System's CSS Custom Properties
$success-message-background:              #ebf4e2;
$error-message-background:                #fde0db;
$info-message-background:                 #e5f2f6;

// Modal Drawer
$drawer-width:                            580px;
$wide-drawer-width:                       900px;

:export {
  font-base-size: $font-size-base
}