.toast {
  top: 10px;
  left: 0;
  z-index: 5000;
  transform: translate(0, -200%);

  &.open {
    transform: translate(0, 0);
    transition: transform 0.5s ease-out;
  }

  .alert {
    max-width: 930px;
    margin: 0 30px;
  }
}
@media (max-width: 575px) {
  .toast {
    top: 0;

    .alert {
      width: 100%;
      margin: 0;
      border-radius: 0;
    }
  }
}
