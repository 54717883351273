.filter-search-component {
  .search-container {
    .search {
      &-icon {
        top: 26px;
        left: 8px;
      }

      &-icon-clear {
        top: 26px;
        right: 15px;
        cursor: pointer;
      }

      &-input-wrapper {
        max-width: 400px;
      }

      &-input {
        width: 100%;
        padding: 0.5rem 2.25rem;
        
        &::placeholder {
          opacity: 0.5;
        }
      }

      &-results-wrapper {
        padding-left: 4px;
        overflow-y: scroll;

        ul {
          margin-left: 0;
        }

        .list-group-item {
          cursor: pointer;
          padding-left: 0;

          .item-client-company {
            float: right;
          }

          &:first-child {
            border-top: none;
          }

          &:hover {
            background: var(--color-neutral-50);
          }

          &.selected {
            background: var(--color-primary-50);
          }
        }
      }
    }
  }
}
