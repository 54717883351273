@import '../../../sass/vars';

.sso-toggle {
  &.react-toggle {
    .react-toggle-track {
      background-color: $grayWhite;
      border: 1px solid $lightGray;
    }

    .react-toggle-thumb {
      border: 1px solid $lightGray;
    }

    &--checked {
      .react-toggle-track {
        background-color: $primary;
      }

      &:hover {
        .react-toggle-track {
          background-color: $primary;
        }
      }

      .react-toggle-thumb {
        border-color: $primary;
      }
    }

    &:hover {
      background-color: $grayWhite;
      border-radius: 30px;

      .react-toggle-track {
        border: 1px solid $lightGray;
        background-color: $grayWhite;

        &:hover {
          background-color: $grayWhite;
        }
      }

      &:not(.react-toggle--disabled) {
        .react-toggle-track {
          background-color: $grayWhite;
        }
      }

      .react-toggle-thumb {
        border: 1px solid $lightGray;
      }

      &.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
        background-color: $primary;
      }
    }
  }
}
