@import '../../../sass/vars.scss';

.logo-container {
  min-height: 170px;

  &.disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }

  .edit-controls {
    top: 5px;
    right: 5px;
    border-color: transparent;

    &:hover {
      border: 1px solid $lightGray;
    }
  }
}
