.drawer {
  .totp-secret-for-copy {
    max-width: 300px;
    display: inline-block;
  }

  .verification-code {
    .form-group {
      margin-left: -8px;
    }

    input {
      width: 250px;
    }
  }
}
