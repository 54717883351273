.vh-100 {
  min-height: calc(100vh - 55px) !important;
}
@media (max-width: 575px) {
  .vh-100 {
    min-height: calc(100vh - 110px) !important;
  }
}

.vw-100 {
  min-width: 100vw !important;
}

.modal ul {
  margin-left: -25px;
}

.mw-960 {
  max-width: 960px;
}
