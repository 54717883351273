@import './vars';
body {
  font-size: 0.875rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 1em;
}
.h1, h1 {
  font-size: 1.0625rem;
}
.h2, h2 {
  font-size: 1.0625rem;
}
.h3, h3 {
  font-size: 1.4875rem;
}
.h4, h4 {
  font-size: 1.275rem;
}
.h5, h5 {
  font-size: 1.0625rem;
}

h4 {
  font-weight: 400;
}
p {
  font-size: 0.875rem;
}
a {
  color: var(--color-primary-600);
}

// Typography
.text-gray {
  color: var(--color-neutral-400) !important;
}

.text-para {
  font-size: 16px !important;
}

.small {
  font-size: 10px;
}

// Cards
.card {
  border-radius: 0;
}

.card-text {
  font-size: 16px;
}

// Navigation
.nav-link {
  &:hover {
    background: var(--color-neutral-50);
  }
}

.horizontal-nav {
  color: $gray;
  background-color: #FFFFFF;

  .nav-link {
    cursor: pointer;
    padding: 8px 16px !important;
    margin: auto 0;
    border-bottom: 4px solid #FFFFFF;

    &:hover {
      color: $darkGray;
    }

    &.active {
      color: $darkGray;
      border-bottom: 4px solid $primary;
    }
  }

  .dropdown-item {
    cursor: pointer;
    padding: 8px 16px !important;
    margin: auto 0;
    outline: 0;
    background: inherit;
    color: inherit;
    font-size: .75rem;
  }
  .dropdown-menu {
    font-size: .85rem;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
}
// Search dropdown
.search-toggle, .account-dropdown {
  .btn:not(.btn-link) {
    text-transform: none;
    letter-spacing: normal;
  }
}

.vertical-nav {
  color: $darkGray;
  padding-top: 1rem;

  .nav-item {
    padding-top: 0 !important;
  }

  .nav-link {
    padding-top: 1rem;
    color: var(--color-primary-600);

    &:hover {
      color: var(--color-primary-700);
      border-left: 4px solid $primary;

      svg {
        margin-left: -4px;
        color: var(--color-primary-700);
      }
    }

    &.active {
      background: var(--color-neutral-300);
      border-left: 4px solid var(--color-primary-700);
      color: var(--color-primary-700);

      svg {
        margin-left: -4px;
        color: var(--color-primary-700);
      }
    }
  }
}

// icons

/* Rules for sizing the icons. */
.material-icons.md-18 { font-size: 18px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-32 { font-size: 32px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }
.material-icons.md-72 { font-size: 72px; }

/* Rules for using icons as black on a light background. */
.material-icons.md-dark { color: rgba(0, 0, 0, 0.54); }
.material-icons.md-dark.md-inactive { color: rgba(0, 0, 0, 0.26); }

/* Rules for using icons as #FFFFFF on a dark background. */
.material-icons.md-light { color: rgba(255, 255, 255, 1); }
.material-icons.md-light.md-inactive { color: rgba(255, 255, 255, 0.3); }

// Alerts
.alert {
  padding: 16px;
  border: none !important;

  &.alert-danger {
    background: $error-message-background;
  }

  &.alert-success {
    background: $success-message-background;
  }

  &.alert-info {
    background: $info-message-background;
  }
}

// Forms
.form-control {
  &.is-invalid:focus {
    box-shadow: 0 0 1px 1px rgba($dangerColor, 0.25);
  }

  &.is-valid:focus {
    box-shadow: 0 0 1px 1px rgba($successColor, 0.25);
  }
}

form hr {
  margin: 35px 0;
}

.form-text {
  font-size: 14px;
}
.form-control-sm {
  height: auto;
}

// Badges
.badge-pill {
  font-size: 14px;
}

.badge-success {
  color: $darkGray;
  background-color: #e5f8ee;
  border: #2bb55c 1px solid;
}

.badge-secondary {
  color: $darkGray;
  background-color: #eee;
  border: #bfbfbf 1px solid;
}

h1 .badge {
  margin-left: 20px;
  vertical-align: middle;
}

// Modal drawer
.modal.drawer {
  transition-delay: 0.5s;

  .modal-dialog {
    margin: 0 0 0 auto;
    height: 100%;
    max-width: $drawer-width;
    @media (max-width: 650px) {
      max-width: 90%;
    }

    .modal-content {
      height: 100%;
      border: none;
      border-radius: 0;
    }

    .modal-header {
      background-color: #f5f5f5;
    }

    .modal-body {
      max-height: calc(100vh - 100px);
      overflow-y: auto;
    }
  }

  &.wide .modal-dialog {
    max-width: $wide-drawer-width;
  }

  &.fade {
    .modal-dialog {
      transform: translate(100%, 0);
    }
  }

  &.show {
    transition-delay: 0s;

    .modal-dialog {
      transform: translate(0, 0);
    }
  }

  &.overlay + .modal-backdrop {
    opacity: 0;
  }

  .alert {
    border-radius: 0;
  }
}

// Tables
.table {
  &.selectable tr {
    &:hover {
      background-color: var(--color-neutral-50);
      cursor: pointer;
    }
  }

  thead th {
    background-color: #fafafa;
    border-top: none;
    border-bottom-width: 1px;
  }

  .small {
    font-size: 85%;
  }
}

// Breadcrumbs
.breadcrumb {
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  margin: 8px 0;
  padding: 0;

  .breadcrumb-item {
    font-size: 16px;
    font-weight: 300;
    display: flex;
    align-items: center;
    padding: 0;

    a {
      color: inherit;
    }
  }
}

// Make scrollbars visible on Chrome
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  background: inherit;
}

#asureForceModal {
  .form-control-sm {
    padding-left: 0;
    padding-right: 0;
  }
}
// Buttons
.btn-group>.btn, .btn-group-vertical>.btn {
  flex: 0 1 auto;
}
.btn-white {
  border-color: #d4d4d4;
}

.close {
  font-size: 1.45rem;
}

// form label 
.form-group label {
  font-size: 1rem;
}
//integration-user-header
.user-credentials-header {
  font-weight: 500;
  font-size: 1rem;
}