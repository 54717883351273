@import '../../../sass/vars.scss';

.dropzone,
.logo-container {
  border: 2px dashed $border-color;
}

.dropzone {
  transition: all 0.5s;
  cursor: pointer;

  .upload-icon {
    color: $lightGray;
  }

  .drag-over {
    &:after {
      content: ' ';
      height: 100%;
      width: 100%;
      background: $primary;
      opacity: 0.1;
      position: absolute;
    }
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }
}
