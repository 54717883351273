.account-dropdown {
  .account-dropdown-content {
    &:focus {
      box-shadow: none;
    }
  }

  .dropdown-menu {
    top: -2px !important;
    left: -15px !important;
    min-width: 0;
    border-radius: 0;
  }

  .dropdown-item {
    &:hover {
      background: var(--color-neutral-50);
    }

    &.selected {
      background: var(--color-primary-50);
    }
  }
}
