.setup-container {
  height: 100vh;

  h4 {
    font-family: 'Roboto', 'Open Sans', sans-serif;
    font-size: 32px;
    font-weight: 300;
    color: rgb(78, 78, 79);
    text-align: center;
    margin-bottom: 10px;
  }

  .setup-wrapper {
    max-width: 600px;
  }

  .logo {
    object-fit: contain;
    max-width: 270px;
    max-height: 200px;
  }

  .asure-footer {
    line-height: 32px;
    background-color: #0d5779;
    color: rgba(255, 255, 255, 0.87);
  }

  .card {
    margin-bottom: 20px;

    .verification-code {
      max-width: 450px;
    }

    .card-footer {
      background: white;
      border: 0;

      .setup-buttons {
        max-width: 338px;
        margin: 0 auto 20px;
        display: flex;

        button {
          width: 100%;
        }
      }
    }
  }

  // override the drawer styles to reverse the order of the buttons
  // and put spacing between the buttons consistent with the register UX
  #setup-mfa-device {
    .setup-buttons {
      flex-direction: column-reverse;

      .btn-white {
        margin-top: 0.5rem;
      }
    }
  }
}

