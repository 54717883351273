@import '../../sass/vars';

button.btn.card-button {
  text-transform: none !important;
  border-width: 2px;
  white-space: normal;
  background-color: transparent !important;

  svg {
    color: $gray;
  }

  .icon-wrapper {
    border-radius: 50%;
    background-color: #f0f0f0;
    height: 3rem;
    width: 3rem;
  }

  &.active {
    background-color: #f2fafc !important;
    border-color: $input-focus-border-color !important;
    box-shadow: $input-focus-box-shadow !important;

    i {
      background-color: #c5e7f2;
    }
  }
  h5 {
    color: #000000;
  }
  p {
    margin: 0;
  }
}
