@import './vars.scss';

.drawer {
  ol {
    margin-left: 0;
    padding-left: 0;
    counter-reset: item;

    > li {
      margin: 0 15px 0 50px;
      padding-left: 0;
      counter-increment: item;
      list-style: none inside;
      margin-bottom: 10px;

      &:before {
        position: absolute;
        left: 25px;
        content: counter(item);
        padding: 0 6.5px;
        color: white;
        background: $primary;
        border-radius: 15px;
      }

      ul > li {
        margin-top: 10px;
        list-style: circle;
        list-style-type: disc;
      }
    }
  }

  input[type=number] {
    width: 150px !important;
  }

  label {
    font-size: 14px;
  }
}
